import { authProvider } from "./AuthProvider";
import Api from './Api';

class Permissions {
  // TODO: maybe find a better approach later?
    role = {
        PMO: 'pmo',
        MANAGER: 'manager',
        VALIDATOR: 'validator',
        GUEST: 'guest'

    }

    access = {
        "VIEW_SURVEY_TEMPLATE" : "VIEW_SURVEY_TEMPLATE",
        "CREATE_SURVEY_TEMPLATE": "CREATE_SURVEY_TEMPLATE",
        "UPDATE_SURVEY_TEMPLATE": "UPDATE_SURVEY_TEMPLATE",
        "DELETE_SURVEY_TEMPLATE": "DELETE_SURVEY_TEMPLATE",
        "GENERATE_SELF_ASSESSMENT_URL": "GENERATE_SELF_ASSESSMENT_URL",
        "GENERATE_ONSITE_ASSESSMENT_URL": "GENERATE_ONSITE_ASSESSMENT_URL",
        "MANAGE_URLS": "MANAGE_URLS",
        "VIEW_ALL_ASSESSMENTS": "VIEW_ALL_ASSESSMENTS",
        "VIEW_COMPLETED_ASSESSMENTS": "VIEW_COMPLETED_ASSESSMENTS",
        "SEARCH_ALL_ASSESSMENTS": "SEARCH_ALL_ASSESSMENTS",
        "ASSIGN_SELF_ASSESSMENT_CASES": "ASSIGN_SELF_ASSESSMENT_CASES",
        "VIEW_ASSIGNED_CASES": "VIEW_ASSIGNED_CASES",
        "UPDATE_CASE_STATUS": "UPDATE_CASE_STATUS",
        "ASSIGN_CASES_TO_ANOTHER_PERSONNEL":"ASSIGN_CASES_TO_ANOTHER_PERSONNEL",
        "DELETE_ASSESSMENTS":"DELETE_ASSESSMENTS",
        "UPLOAD_DOCUMENTS":"UPLOAD_DOCUMENTS",
        "INBOX":"INBOX",
        "VIEW_DASHBOARD": "VIEW_DASHBOARD",
        VIEW_ALL_ENTITIES: 'VIEW_ALL_ENTITIES',
        CREATE_ENTITY: 'CREATE_ENTITY',
        EDIT_ENTITY: 'EDIT_ENTITY',
        DELETE_ENTITY: 'DELETE_ENTITY',
        VIEW_ALL_USERS: 'VIEW_ALL_USERS',
        VIEW_ALL_CASES: 'VIEW_ALL_CASES',
        ESTABLISH_CASE: 'ESTABLISH_CASE',
        GENERATE_ASSESSMENT_REPORT: 'GENERATE_ASSESSMENT_REPORT',
        UPDATE_ENTITY_STATUS: 'UPDATE_ENTITY_STATUS',
        SEND_ENTITY_QR_CODE: 'SEND_ENTITY_QR_CODE',
        UPDATE_USER_RISK_STATUS: 'UPDATE_USER_RISK_STATUS',
        EDIT_CASE: 'EDIT_CASE',
        DELETE_CASE: 'DELETE_CASE',
        MANAGE_ROLES: 'MANAGE_ROLES'

    }

    masterList = {
        "VIEW_SURVEY_TEMPLATE":[this.role.PMO],
        "CREATE_SURVEY_TEMPLATE": [this.role.PMO],
        "UPDATE_SURVEY_TEMPLATE": [this.role.PMO],
        "DELETE_SURVEY_TEMPLATE" : [this.role.PMO],
        "GENERATE_SELF_ASSESSMENT_URL": [this.role.PMO],
        "GENERATE_ONSITE_ASSESSMENT_URL": [this.role.PMO, this.role.MANAGER, this.role.VALIDATOR],
        "MANAGE_URLS": [this.role.PMO],
        "VIEW_ALL_ASSESSMENTS": [this.role.PMO],
        "VIEW_COMPLETED_ASSESSMENTS": [this.role.PMO, this.role.GUEST],
        "SEARCH_ALL_ASSESSMENTS": [this.role.PMO],
        "ASSIGN_SELF_ASSESSMENT_CASES": [this.role.PMO],
        "VIEW_ASSIGNED_CASES": [this.role.PMO, this.role.MANAGER, this.role.VALIDATOR],
        "UPDATE_CASE_STATUS": [this.role.PMO, this.role.MANAGER, this.role.VALIDATOR],
        "ASSIGN_CASES_TO_ANOTHER_PERSONNEL": [this.role.PMO, this.role.MANAGER, this.role.VALIDATOR],
        "DELETE_ASSESSMENTS": [this.role.PMO],
        "UPLOAD_DOCUMENTS": [this.role.PMO, this.role.MANAGER, this.role.VALIDATOR],
        "INBOX": [this.role.PMO, this.role.MANAGER, this.role.VALIDATOR],
        //"VIEW_DASHBOARD": [this.role.PMO,this.role.MANAGER, this.role.VALIDATOR, this.role.GUEST],
        "VIEW_DASHBOARD": [this.role.PMO],
        VIEW_ALL_ENTITIES: [this.role.PMO, this.role.MANAGER, this.role.VALIDATOR],
        CREATE_ENTITY: [this.role.PMO],
        EDIT_ENTITY: [this.role.PMO],
        DELETE_ENTITY: [this.role.PMO],
        UPDATE_ENTITY_STATUS: [this.role.PMO],
        SEND_ENTITY_QR_CODE: [this.role.PMO],
        VIEW_ALL_USERS: [this.role.PMO, this.role.MANAGER, this.role.VALIDATOR],
        UPDATE_USER_RISK_STATUS: [this.role.PMO],
        VIEW_ALL_CASES:[this.role.PMO, this.role.MANAGER, this.role.VALIDATOR],
        ESTABLISH_CASE: [this.role.PMO],
        EDIT_CASE: [this.role.PMO],
        DELETE_CASE: [this.role.PMO],
        GENERATE_ASSESSMENT_REPORT: [this.role.PMO],
        MANAGE_ROLES: [this.role.PMO]

    }

    getPermissions(role) {
        return Object.keys(this.access).filter(action =>
            this.masterList[action].includes(role)
        )
    }

    getRoles(action) {
        return this.masterList[action] || []
    }

    hasPermission(action, role) {
        return this.getRoles(action).includes(role)
    }

    isValidRole(role) {
        const userRoles  = this.getRoleMapFromJobTitle(role);
        return Array.from(userRoles.values()).every(role => Object.values(this.role).includes(role))
    }

    hasPermissionForCurrentUser(action) {
        const account = (authProvider.getAccount() || {}).idToken || {};
        const roles = account.jobTitle ? account.jobTitle.split(';') : (account.roles || [])
        const userRoles = this.getRoleMapFromJobTitle(roles || [])
        return this.getRoles(action).includes(userRoles.get(`${(this.getCurrentCompany()|| '').toLowerCase()}`))
    
    }

    getCurrentUserRole() {
        const account = (authProvider.getAccount() || {}).idToken || {};
        const roles = account.jobTitle ? account.jobTitle.split(';') : (account.roles || [])
        const userRoles = this.getRoleMapFromJobTitle(roles || [])
        
        return (userRoles.get(`${(this.getCurrentCompany()|| '').toLowerCase()}`)|| '').toLowerCase(); 
    }

    getRoleMapFromJobTitle(roles){
        let userRoleMap = new Map();
        roles.forEach(item => {
            const str = item.split(':')
            userRoleMap.set(str[0], str[1]);
        });
        return userRoleMap;

    }

    getCurrentUserAccount() {
        const account = (authProvider.getAccount() || {}).idToken || {};
        return account
    }

    setCurrentCompany(selectedCompany) {
        localStorage.setItem('_currentcompany', selectedCompany);
    }

    getCurrentCompany(){
        return localStorage.getItem('_currentcompany');
    }

    removeCurrentCompany() {
        return localStorage.removeItem('_currentcompany')
    }

    async logout() {
        return Api._post('/api/admin/auth/logout')
    }

    async login() {
        return Api._post('/api/admin/auth/login')
    }

}

export default new Permissions()
