import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createHashHistory as createHistory } from 'history'
import rootReducer from './reducers'

export const history = createHistory()

const initialState = {}

const middleware = [thunk]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(rootReducer, initialState, composeEnhancers(
  applyMiddleware(...middleware),
))


// export default function configureStore(initialState) {
//     const store = createStore(rootReducer, initialState, applyMiddleware(...middleware));
//     return store;
//   }
