import { 
    CLEAR_ENTITY,
    RESET_ENTITY_LIST, 
    GET_ALL_ENTITIES, 
    GET_ALL_ENTITIES_ERROR, 
    GET_ENTITY_SUCCESS, 
    GET_ENTITY_ERROR, 
    DELETE_ENTITY_SUCCESS, 
    DELETE_ENTITY_ERROR, 
    EDIT_ENTITY_SUCCESS, 
    CREATE_ENTITY_SUCCESS, 
    EDIT_ENTITY_ERROR, 
    CREATE_ENTITY_ERROR, 
    UPDATE_ENTITY_STATUS_ERROR,
    UPDATE_ENTITY_STATUS_SUCCESS} from '../actions/types';

const initialState = {
    entities: [],
    entity: null,
    error: null,
    isDeleted: false,
    deleteError: null,
    isUpdated: false,
    updateError: null,
    isApiCallCompleted: false,
    isStatusUpdated: false,
    totalEntities:0
}

const entityReducer = (state, action) => {
    switch(action.type){
        case GET_ALL_ENTITIES:
                return Object.assign({}, state, { 
                    entities: action.payload.entities, 
                    isUpdated:false, 
                    isApiCallCompleted: true,
                    totalEntities: action.payload.size
                });
        case GET_ALL_ENTITIES_ERROR:
                return Object.assign({}, state, { error: action.payload} )
        case GET_ENTITY_SUCCESS:
                return Object.assign({}, state, { entity: action.payload});
        case GET_ENTITY_ERROR:
                    return Object.assign({}, state, { error: action.payload} )
        case DELETE_ENTITY_SUCCESS:
                return Object.assign({}, state, { 
                    isDeleted: true ,
                    entities : action.payload.entities,
                    totalEntities: action.payload.size
                } )
        case DELETE_ENTITY_ERROR:
            return Object.assign({}, state, { isDeleted: false, deleteError: action.payload} )
        case EDIT_ENTITY_SUCCESS: 
            return Object.assign({}, state,{isUpdated: true, entities: action.payload } )
        case CREATE_ENTITY_SUCCESS: 
            return Object.assign({}, state, {
                isUpdated: true, 
                entities: action.payload.entities,
                totalEntities: action.payload.size
            })
        case CREATE_ENTITY_ERROR:
            return Object.assign({}, state, {  updateError: action.payload});
        case EDIT_ENTITY_ERROR:
            return Object.assign({}, state, { updateError: action.payload})
        case UPDATE_ENTITY_STATUS_ERROR:
            return Object.assign({}, state, { updateError: action.payload})
        case UPDATE_ENTITY_STATUS_SUCCESS:
            return Object.assign({}, state, { entity: action.payload, isStatusUpdated:true});
        case CLEAR_ENTITY: 
            return Object.assign({}, state, { entity: null } )
        case RESET_ENTITY_LIST:
            return Object.assign({}, state, { isStatusUpdated:false, isUpdated:false, updateError: null,isDeleted: false, error: null, deleteError: null, isApiCallCompleted:false } )
        default:
                if(state)
                    return state;
                else
                    return initialState
    }
}

export default entityReducer;   