import { GET_ALL_ASSESMENT_FORM_ERROR, 
        GET_ALL_ASSESMENT_FORM_SUCCESS, 
        CREATE_ASSESSMENT_FORM_SUCCESS,
        CREATE_ASSESSMENT_FORM_ERROR, 
        EDIT_ASSESSMENT_FORM_SUCCESS, 
        EDIT_ASSESSMENT_FORM_ERROR,
        CLEAR_UPDATE_ERROR,
        DELETE_ASSESSMENT_FORM_ERROR,
        DELETE_ASSESSMENT_FORM_SUCCESS} from '../actions/types';

const initialState = {
        assessmentForms: [],
        error: null,
        updateError: null,
        deleteError: null,
        isDeleted: false,
        isUpdated: false,
        isAssessmentFormsFetched: false,
        totalAssessmentForms:0
}

const assessmentReducer = (state, action) => {
    switch(action.type){
        case GET_ALL_ASSESMENT_FORM_SUCCESS:
                return Object.assign({}, state, { 
                        assessmentForms: action.payload.assessmentForms, 
                        isAssessmentFormsFetched: true,
                        totalAssessmentForms: action.payload.size
                });
        case GET_ALL_ASSESMENT_FORM_ERROR:
                return Object.assign({}, state, { error: action.payload} );
        case CREATE_ASSESSMENT_FORM_SUCCESS:
                return Object.assign({}, state, { 
                        isUpdated: true,  
                        assessmentForms : action.payload,
                        totalAssessmentForms: state.totalAssessmentForms +1
                })
        case CREATE_ASSESSMENT_FORM_ERROR:
                return Object.assign({}, state, {updateError:action.payload })
        case EDIT_ASSESSMENT_FORM_SUCCESS:
                return Object.assign({}, state, { isUpdated: true, assessmentForms : action.payload})
        case EDIT_ASSESSMENT_FORM_ERROR:
                return Object.assign({}, state, {updateError:action.payload });
        case CLEAR_UPDATE_ERROR:
                return Object.assign({}, state, { updateError: null, deleteError: null, isDeleted:false, error: null, isUpdated: false, isAssessmentFormsFetched:false})
        case DELETE_ASSESSMENT_FORM_SUCCESS:
                return Object.assign({}, state, { 
                        isDeleted: true ,
                        assessmentForms : action.payload.assessmentForms,
                        totalAssessmentForms: action.payload.size
                } )
        case DELETE_ASSESSMENT_FORM_ERROR:
                return Object.assign({}, state, { isDeleted: false, deleteError: action.payload} )
        default:
                if(state)
                    return state;
                else
                    return initialState
    }
}

export default assessmentReducer;   