import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import Config from './config';

const adConfig = Config.getADConfig()

const authMode = Config.getAuthMode()

// Msal Configurations
const applicationConfig = {
  auth: {
    authority: authMode === 'AD' ? `https://login.microsoftonline.com/${adConfig.tenantDomain}` : `https://${adConfig.tenantName}.b2clogin.com/${adConfig.tenantDomain}/${adConfig.policy}`,
    clientId: adConfig.clientId,
    validateAuthority: authMode === 'AD'
    // redirectUri: 'http://localhost:3000',
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};

// Authentication Parameters
const authenticationParameters = {
  scopes: [`https://${adConfig.tenantDomain}/${adConfig.scope}`]
}

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/auth.html'
}

// yeah.. Until MSAD fixes proper redirection or We find a better way
let authProvider;
try {
  authProvider = new MsalAuthProvider(applicationConfig, authenticationParameters, options)
} catch (error) {
  window.location.href = window.location.origin
}

export { authProvider }
