import {  
    RESET_CASE_LIST, 
    EDIT_CASE_ERROR, 
    GET_ALL_CASES, 
    GET_ALL_CASES_ERROR,
    GET_CASE_ERROR, 
    GET_CASE_SUCCESS,
    EDIT_CASE_SUCCESS, 
    CREATE_CASE_SUCCESS, 
    DELETE_CASE_SUCCESS, 
    DELETE_CASE_ERROR, 
    CREATE_CASE_ERROR,
    CLEAR_CASE,
    CREATE_CASE_PROGRESS,
    CONTACT_TRACE_FOR_CASE_ERROR,
    CONTACT_TRACE_FOR_CASE_SUCCESS,
CONTACT_TRACE_FOR_ENTITY_SUCCESS,
CONTACT_TRACE_FOR_ENTITY_ERROR} 
from '../actions/types';

const initialState = {
    caseList: [],
    caseById: null,
    error: null,
    isDeleted: false,
    deleteError: null,
    isUpdated: false,
    updateError: null,
    isApiCallCompleted: false,
    isCreated: false,
    shouldFetchCaseList:true,
    size:0,
    totalRecovered: 0,
    totalInfected:0,
    totalDeceased: 0,
    contactTraces: null,
    entityContactTraces: null
}

const caseReducer = (state, action) => {
    switch(action.type){
        case GET_ALL_CASES:
                return Object.assign({}, state, { 
                    caseList: action.payload.cases, 
                    isUpdated:false, 
                    isApiCallCompleted: true,
                    size: action.payload.size,
                    totalRecovered:action.payload.totalRecovered,
                    totalInfected:action.payload.totalInfected,
                    totalDeceased:action.payload.toataDeceased
                });
        case GET_ALL_CASES_ERROR:
                return Object.assign({}, state, { error: action.payload, isApiCallCompleted:true} )
        case GET_CASE_SUCCESS:
                return Object.assign({}, state, { caseById: action.payload});
        case GET_CASE_ERROR:
                    return Object.assign({}, state, { error: action.payload} )
        case DELETE_CASE_SUCCESS:
                return Object.assign({}, state, { 
                    isDeleted: true ,
                    caseList : action.payload.cases,
                    size: action.payload.size,
                    totalRecovered:action.payload.totalRecovered,
                    totalInfected:action.payload.totalInfected,
                    totalDeceased:action.payload.toataDeceased
                })
        case DELETE_CASE_ERROR:
            return Object.assign({}, state, { isDeleted: false, deleteError: action.payload} )
        case EDIT_CASE_SUCCESS: 
            return Object.assign({}, state,{
                isUpdated: true, 
                caseList: action.payload.caseList,
                totalRecovered:action.payload.totalRecovered,
                totalInfected:action.payload.totalInfected,
                totalDeceased:action.payload.toataDeceased
            })
        case CREATE_CASE_PROGRESS:
            return Object.assign({}, state, { shouldFetchCaseList: false})
        case CREATE_CASE_SUCCESS: 
            return Object.assign({}, state, {
                isCreated: true, 
                caseList: action.payload.caseList,
                size: action.payload.size,
                totalRecovered:action.payload.totalRecovered,
                totalInfected:action.payload.totalInfected,
                totalDeceased:action.payload.toataDeceased
            } )
        case CREATE_CASE_ERROR:
            return Object.assign({}, state, {  updateError: action.payload});
        case EDIT_CASE_ERROR:
            return Object.assign({}, state, { updateError: action.payload})
        case CONTACT_TRACE_FOR_CASE_SUCCESS:
            return Object.assign({}, state, { contactTraces: action.payload});
        case CONTACT_TRACE_FOR_CASE_ERROR:
            return Object.assign({}, state, { error: action.payload} )
        case CONTACT_TRACE_FOR_ENTITY_SUCCESS:
            return Object.assign({}, state, { entityContactTraces: action.payload});
        case CONTACT_TRACE_FOR_ENTITY_ERROR:
            return Object.assign({}, state, { error: action.payload} )
        case CLEAR_CASE: 
            return Object.assign({}, state, { caseById: null, contactTraces:null, entityContactTraces:null} )
        case RESET_CASE_LIST:
            return Object.assign({}, state, { isCreated:false, isUpdated:false, updateError: null,isDeleted: false, error: null, deleteError: null, isApiCallCompleted:false } )
        default:
                if(state)
                    return state;
                else
                    return initialState
    }
}

export default caseReducer;   