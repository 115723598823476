import {  CLEAR_USER, 
    RESET_USER_LIST,  
    GET_ALL_USERS_SUCCESS,  
    GET_ALL_USERS_ERROR, 
    GET_USER_ERROR,  
    GET_USER_SUCCESS, 
    UPDATE_USER_STATUS_ERROR, 
    UPDATE_USER_STATUS_SUCCESS, 
    USER_STATUS_UPDATE_PROGRESS, 
    GET_USER_DECLARATION_SUCCESS, 
    GET_USER_DECLARATION_ERROR,
    GET_ADMIN_USER_SUCCESS,
    GET_ADMIN_USER_ERROR, 
    UPDATE_ADMIN_USER_SUCCESS,
    UPDATE_ADMIN_USER_ERROR,
    RESET_ADMIN_USER_LIST,
    INVITE_USER_SUCCESS,
    INVITE_USER_ERROR
}  from '../actions/types';

const initialState = {
    userList: [],
    user: null,
    error: null,
    updateError: null,
    isApiCallCompleted: false,
    isStatusUpdated: false,
    shouldFetchUserList: true,
    usersSize:0,
    declaration: null,
    adminUserList: [],
    adminUserFetched: false,
    isUserUpdated:false,
    isUserInvited:false
}

const userReducer = (state, action) => {
    switch(action.type){
        case GET_ALL_USERS_SUCCESS:
                return Object.assign({}, state, { 
                    userList: action.payload.users, 
                    isApiCallCompleted: true,
                    usersSize: action.payload.totalUsers
                });
        case GET_ALL_USERS_ERROR:
                return Object.assign({}, state, { error: action.payload} )
        case GET_USER_SUCCESS:
                return Object.assign({}, state, { user: action.payload , isApiCallCompleted:false});
        case GET_USER_ERROR:
                    return Object.assign({}, state, { error: action.payload} )
        case USER_STATUS_UPDATE_PROGRESS:
            return Object.assign({}, state, { shouldFetchUserList : false})
        case UPDATE_USER_STATUS_ERROR:
            return Object.assign({}, state, { updateError: action.payload})
        case UPDATE_USER_STATUS_SUCCESS:
            return Object.assign({}, state, { userList: action.payload, isStatusUpdated:true});
        case GET_USER_DECLARATION_SUCCESS:
            return Object.assign({}, state, { declaration: action.payload });
        case GET_USER_DECLARATION_ERROR:
            return Object.assign({}, state, { error: action.payload})
        case CLEAR_USER: 
            return Object.assign({}, state, { user: null, declaration: null } )
        case GET_ADMIN_USER_SUCCESS:
            return Object.assign({}, state, { adminUserList: action.payload.users, adminUserFetched:true})
        case GET_ADMIN_USER_ERROR:
            return Object.assign({}, state, { error: action.payload, adminUserFetched: false})
        case UPDATE_ADMIN_USER_SUCCESS:
            return Object.assign({}, state, { adminUserList: action.payload, isUserUpdated:true})
        case UPDATE_ADMIN_USER_ERROR:
            return Object.assign({}, state, { updateError: action.payload})
        case INVITE_USER_SUCCESS:
            return Object.assign({}, state, { adminUserList: action.payload, isUserInvited:true})
        case INVITE_USER_ERROR:
            return Object.assign({}, state, { updateError: action.payload})
        case RESET_USER_LIST:
            return Object.assign({}, state, { 
                shouldFetchUserList: true, 
                isStatusUpdated:false, 
                updateError: null, 
                error: null, 
                isApiCallCompleted:false
            } )
        case RESET_ADMIN_USER_LIST: 
            return Object.assign({}, state, { 
                updateError: null, 
                error: null,  
                adminUserFetched:false, 
                isUserUpdated:false,
                isUserInvited:false 
            } )
        default:
                if(state)
                    return state;
                else
                    return initialState
    }
}

export default userReducer;   