import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label} from 'reactstrap'
import IdleTimer from 'react-idle-timer';

class IdleTimeOut extends Component {

    constructor(props){
        super(props);
        this.idleTimer = null
        this.state = {
            timeout:1000 * 60 * 25,  // 25 minutes
            showModal: false,
            minutes: 5,
            seconds: 0
        }
    }

    componentDidUpdate() {
        if(this.state.minutes === 0 && this.state.seconds === 0 && this.state.showModal){
            this.handleLogout()
        }
    }

    renderAlertModal() {
        const { minutes, seconds } = this.state;
        return (
            <Modal isOpen={this.state.showModal}>
                <ModalHeader>You Have Been Idle!</ModalHeader>
                <ModalBody>
                    <Label>You will get logout  and all your changes will be lost.</Label>
                    <br/>
                    <Label>Time Remaining <strong>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</strong></Label>
                </ModalBody>
                <ModalFooter>
                    <Button color='link' onClick={this.handleClose}>Stay</Button>
                    <Button className='confirmButton' onClick={this.handleLogout}>Logout</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        return (
            <React.Fragment>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    debounce={250}
                    timeout={this.state.timeout} // 30 min timeout
                    onIdle={this.onIdleTimeout}           
                />
                {this.state.showModal && this.renderAlertModal()}
            </React.Fragment>
        )
    }

    handleClose = () => {
        this.setState({showModal: false, minutes:5, seconds: 0});
        this.idleTimer.reset();
        clearInterval(this.myInterval);
    }

    handleLogout = () =>  {
        this.setState({showModal: false})
        this.props.logout();
    }

    onIdleTimeout = () => {
        this.setState(state => ({ showModal: true}));
        this.startTimer()
    }

    startTimer = () => {
        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)                       
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            } 
        }, 1000)
    }

    
}

export default IdleTimeOut;