import { AuthenticationActions, AuthenticationState } from 'react-aad-msal';

const initialState = {
  isAuthenticated: false,
  access_token: null,
  loginError: null,

  initializing: false,
  initialized: false,
  idToken: null,
  accessToken: null,
  state: AuthenticationState.Unauthenticated,
};


// const loginReducer = (state, action) => {
//   switch (action.type) {
//     case USER_LOGIN_SUCCESS:
//       return Object.assign({}, state, {
//         access_token: action.payload.access_token,
//         isAuthenticated: true,
//         loginError: null,
//         user: action.payload.user || {}
//       });
//     case USER_LOGIN_ERROR:
//       return Object.assign({}, state, { loginError: action.payload, isAuthenticated: false, user: {} })
//     default:
//       if (state)
//         return state;
//       else
//         return initialState
//   }
// }

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthenticationActions.Initializing:
      return {
        ...state,
        initializing: true,
        initialized: false,
      };
    case AuthenticationActions.Initialized:
      return {
        ...state,
        initializing: false,
        initialized: true,
      };
    case AuthenticationActions.AcquiredIdTokenSuccess:
      return {
        ...state,
        idToken: action.payload,
      };
    case AuthenticationActions.AcquiredAccessTokenSuccess:
      return {
        ...state,
        accessToken: action.payload,
      };
    case AuthenticationActions.AcquiredAccessTokenError:
      return {
        ...state,
        accessToken: null,
      };
    case AuthenticationActions.LoginSuccess:
      return {
        ...state,
        account: action.payload.account,
      };
    case AuthenticationActions.LoginError:
    case AuthenticationActions.AcquiredIdTokenError:
    case AuthenticationActions.LogoutSuccess:
      return { ...state, idToken: null, accessToken: null, account: null };
    case AuthenticationActions.AuthenticatedStateChanged:
      return {
        ...state,
        state: action.payload,
      };
    default:
      if (state)
        return state;
      else
        return initialState
  }
};

export default loginReducer;
