export const GET_ALL_TEMPLATES='GET_ALL_TEMPLATES';
export const GET_ALL_TEMPLATES_ERROR = 'GET_ALL_TEMPLATES_ERROR';
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_ERROR = 'GET_TEMPLATE_ERROR';
export const DELETE_TEMPLATE_SUCCESS='DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_ERROR = 'DELETE_TEMPLATE_ERROR';
export const CREATE_TEMPLATE_ERROR = 'CREATE_TEMPLATE_ERROR';
export const EDIT_TEMPLATE_ERROR = 'EDIT_TEMPLATE_ERROR';
export const GET_OPEN_ASSESMENT_RESPONSE_SUCCESS='GET_OPEN_ASSESMENT_RESPONSE_SUCCESS';
export const ASSESMENT_RESPONSE_ERROR = 'ASSESMENT_RESPONSE_ERROR';
export const GET_COMPLETED_ASSESMENT_RESPONSE_SUCCESS = 'GET_COMPLETED_ASSESMENT_RESPONSE_SUCCESS';
export const GET_USER_ASSESMENT_RESPONSE_SUCCESS='GET_USER_ASSESMENT_RESPONSE_SUCCESS';
export const GET_ASSIGNED_ASSESMENT_RESPONSE_SUCCESS='GET_ASSIGNED_ASSESMENT_RESPONSE_SUCCESS';
export const GET_ASSESMENT_RESPONSE_SUCCESS='GET_ASSESMENT_RESPONSE_SUCCESS';
export const EDIT_ASSESSMENT_RESPONSE_SUCCESS = 'EDIT_ASSESSMENT_RESPONSE_SUCCESS';
export const EDIT_ASSESSMENT_RESPONSE_ERROR = 'EDIT_ASSESSMENT_RESPONSE_ERROR';
export const GET_ALL_ASSESMENT_FORM_SUCCESS='GET_ALL_ASSESMENT_FORM_SUCCESS';
export const GET_ALL_ASSESMENT_FORM_ERROR = 'GET_ALL_ASSESMENT_FORM_ERROR';
export const CREATE_ASSESSMENT_FORM_SUCCESS = 'CREATE_ASSESSMENT_FORM_SUCCESS';
export const CREATE_ASSESSMENT_FORM_ERROR = 'CREATE_ASSESSMENT_FORM_ERROR';
export const EDIT_ASSESSMENT_FORM_SUCCESS = 'EDIT_ASSESSMENT_FORM_SUCCESS';
export const EDIT_ASSESSMENT_FORM_ERROR = 'EDIT_ASSESSMENT_FORM_ERROR';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const LOGIN_REQUIRED_ERROR = 'LOGIN_REQUIRED_ERROR';
export const CLEAR_TEMPLATE = 'CLEAR_TEMPLATE';
export const CLEAR_TEMPLATE_ERROR = 'CLEAR_TEMPLATE_ERROR';
export const CLEAR_UPDATE_ERROR = 'CLEAR_UPDATE_ERROR'
export const CLEAR_ASSESSMENT_ERROR = 'CLEAR_ASSESSMENT_ERROR';
export const TEMPLATE_UPDATE_SUCCESS = 'TEMPLATE_UPDATE_SUCCESS';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const DELETE_ASSESSMENT_FORM_SUCCESS='DELETE_ASSESSMENT_FORM_SUCCESS';
export const DELETE_ASSESSMENT_FORM_ERROR = 'DELETE_ASSESSMENT_FORM_ERROR';
export const SEND_ASSESSMENT_FORM_AS_EMAIL_SUCCESS = 'SEND_ASSESSMENT_FORM_AS_EMAIL_SUCCESS';
export const SEND_ASSESSMENT_FORM_AS_EMAIL_ERROR = 'SEND_ASSESSMENT_FORM_AS_EMAIL_ERROR';
export const TEMPLATE_UPDATE_PROGRESS = 'TEMPLATE_UPDATE_PROGRESS';
export const RESET_TEMPLATE_LIST = 'RESET_TEMPLATE_LIST';
export const EDIT_ASSESSMENT_SUCCESS = 'EDIT_ASSESSMENT_SUCCESS';
export const DELETE_ASSESSMENT_SUCCESS='DELETE_ASSESSMENT_SUCCESS';
export const DELETE_ASSESSMENT_ERROR = 'DELETE_ASSESSMENT_ERROR';
export const CLEAR_ENTITY = 'CLEAR_ENTITY'
export const GET_ALL_ENTITIES='GET_ALL_ENTITIES';
export const GET_ALL_ENTITIES_ERROR = 'GET_ALL_ENTITIES_ERROR';
export const GET_ENTITY_SUCCESS = 'GET_ENTITY_SUCCESS';
export const GET_ENTITY_ERROR = 'GET_ENTITY_ERROR';
export const DELETE_ENTITY_SUCCESS='DELETE_ENTITY_SUCCESS';
export const DELETE_ENTITY_ERROR = 'DELETE_ENTITY_ERROR';
export const CREATE_ENTITY_ERROR = 'CREATE_ENTITY_ERROR';
export const EDIT_ENTITY_ERROR = 'EDIT_ENTITY_ERROR';
export const EDIT_ENTITY_SUCCESS = 'EDIT_ENTITY_SUCCESS';
export const CREATE_ENTITY_SUCCESS = 'CREATE_ENTITY_SUCCESS';
export const RESET_ENTITY_LIST = 'RESET_ENTITY_LIST'
export const UPDATE_ENTITY_STATUS_ERROR = 'UPDATE_ENTITY_STATUS_ERROR';
export const UPDATE_ENTITY_STATUS_SUCCESS = 'UPDATE_ENTITY_STATUS_SUCCESS';

export const CLEAR_USER = 'CLEAR_USER'; 
export const RESET_USER_LIST = 'RESET_USER_LIST';
export const GET_ALL_USERS_SUCCESS ='GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR';
export const GET_USER_ERROR ='GET_USER_ERROR';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const UPDATE_USER_STATUS_ERROR = 'UPDATE_USER_STATUS_ERROR';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';
export const USER_STATUS_UPDATE_PROGRESS = 'USER_STATUS_UPDATE_PROGRESS';
export const GET_USER_DECLARATION_SUCCESS = 'GET_USER_DECLARATION_SUCCESS';
export const GET_USER_DECLARATION_ERROR = 'GET_USER_DECLARATION_ERROR';


export const RESET_CASE_LIST = 'RESET_CASE_LIST';
export const EDIT_CASE_ERROR = 'EDIT_CASE_ERROR';
export const GET_ALL_CASES = 'GET_ALL_CASES';
export const GET_ALL_CASES_ERROR = 'GET_ALL_CASES_ERROR';
export const GET_CASE_ERROR = 'GET_CASE_ERROR';
export const GET_CASE_SUCCESS = 'GET_CASE_SUCCESS';
export const EDIT_CASE_SUCCESS = 'EDIT_CASE_SUCCESS';
export const CREATE_CASE_SUCCESS = 'CREATE_CASE_SUCCESS';
export const DELETE_CASE_SUCCESS = 'DELETE_CASE_SUCCESS';
export const DELETE_CASE_ERROR = 'DELETE_CASE_ERROR';
export const CREATE_CASE_ERROR = 'CREATE_CASE_ERROR';
export const CLEAR_CASE = 'CLEAR_CASE';
export const CREATE_CASE_PROGRESS= ' CREATE_CASE_PROGRESS'

export const CONTACT_TRACE_FOR_CASE_SUCCESS = 'CONTACT_TRACE_FOR_CASE_SUCCESS';
export const CONTACT_TRACE_FOR_CASE_ERROR = 'CONTACT_TRACE_FOR_CASE_ERROR';
export const CONTACT_TRACE_FOR_ENTITY_ERROR = 'CONTACT_TRACE_FOR_ENTITY_ERROR';
export const CONTACT_TRACE_FOR_ENTITY_SUCCESS = 'CONTACT_TRACE_FOR_ENTITY_SUCCESS';

export const GET_ADMIN_USER_SUCCESS = 'GET_ADMIN_USER_SUCCESS';
export const GET_ADMIN_USER_ERROR = 'GET_ADMIN_USER_ERROR';
export const UPDATE_ADMIN_USER_SUCCESS = 'UPDATE_ADMIN_USER_SUCCESS';
export const UPDATE_ADMIN_USER_ERROR = 'UPDATE_ADMIN_USER_ERROR';
export const RESET_ADMIN_USER_LIST = 'RESET_ADMIN_USER_LIST';

export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_ERROR = 'INVITE_USER_ERROR'

