import { GET_ALL_TEMPLATES, 
    GET_ALL_TEMPLATES_ERROR, 
    DELETE_TEMPLATE_SUCCESS, 
    DELETE_TEMPLATE_ERROR, 
    GET_TEMPLATE_SUCCESS, 
    GET_TEMPLATE_ERROR , 
    CLEAR_TEMPLATE, 
    CREATE_TEMPLATE_ERROR, 
    CLEAR_TEMPLATE_ERROR, 
    EDIT_TEMPLATE_ERROR, 
    TEMPLATE_UPDATE_SUCCESS, 
    CREATE_TEMPLATE_SUCCESS, 
    TEMPLATE_UPDATE_PROGRESS, RESET_TEMPLATE_LIST } from '../actions/types';

const initialState = {
    templates: [],
    template: null,
    error: null,
    isDeleted: false,
    deleteError: null,
    isUpdated: false,
    updateError: null,
    shouldFetchTemplate: true,
    isApiCallCompleted: false,
    totalTemplateCount: 0
}

const templateReducer = (state, action) => {
    switch(action.type){
        case GET_ALL_TEMPLATES:
                return Object.assign({}, state, { 
                    templates: action.payload.assessmentTemplates, 
                    isUpdated:false, 
                    isApiCallCompleted: true,
                    totalTemplateCount: action.payload.size
                });
        case GET_ALL_TEMPLATES_ERROR:
                return Object.assign({}, state, { error: action.payload} )
        case GET_TEMPLATE_SUCCESS:
                return Object.assign({}, state, { template: action.payload});
        case GET_TEMPLATE_ERROR:
                    return Object.assign({}, state, { error: action.payload} )
        case DELETE_TEMPLATE_SUCCESS:
                return Object.assign({}, state, { 
                    isDeleted: true ,
                    templates: action.payload.assessmentTemplates,
                    totalTemplateCount: action.payload.size
                } )
        case DELETE_TEMPLATE_ERROR:
            return Object.assign({}, state, { isDeleted: false, deleteError: action.payload} )
        case TEMPLATE_UPDATE_PROGRESS:
            return Object.assign({}, state, { shouldFetchTemplate : false})
        case TEMPLATE_UPDATE_SUCCESS: 
            return Object.assign({}, state,{isUpdated: true, templates: action.payload } )
        case CREATE_TEMPLATE_SUCCESS: 
            return Object.assign({}, state, {isUpdated: true, templates: action.payload,totalTemplateCount: state.totalTemplateCount+1 } )
        case CREATE_TEMPLATE_ERROR:
            return Object.assign({}, state, {  updateError: action.payload});
        case EDIT_TEMPLATE_ERROR:
            return Object.assign({}, state, { updateError: action.payload})
        case CLEAR_TEMPLATE: 
            return Object.assign({}, state, { template: null } )
        case CLEAR_TEMPLATE_ERROR:
            return Object.assign({}, state, { error: null, deleteError: null, isDeleted: false })
        case RESET_TEMPLATE_LIST:
            return Object.assign({}, state, { shouldFetchTemplate: true, isUpdated:false, updateError: null,isDeleted: false, error: null, deleteError: null, isApiCallCompleted:false } )
        default:
                if(state)
                    return state;
                else
                    return initialState
    }
}

export default templateReducer;   