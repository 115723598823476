import { combineReducers } from 'redux'
import login from './loginReducer';
import template from './templateReducer';
import assessment from './assessmentReducer';
import assessmentForm from './assessmentFormReducer';
import entity from './entityReducer';
import user from './userReducer';
import cases from './caseReducer';

const rootReducer =  combineReducers({
  cases,
  login,
  template,
  assessment,
  assessmentForm,
  entity,
  user
});

export default rootReducer