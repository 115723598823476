import {
    ASSESMENT_RESPONSE_ERROR,
    GET_OPEN_ASSESMENT_RESPONSE_SUCCESS,
    GET_ASSIGNED_ASSESMENT_RESPONSE_SUCCESS,
    EDIT_ASSESSMENT_RESPONSE_SUCCESS,
    EDIT_ASSESSMENT_RESPONSE_ERROR,
    GET_USER_ASSESMENT_RESPONSE_SUCCESS,
    GET_ASSESMENT_RESPONSE_SUCCESS,
    CLEAR_ASSESSMENT_ERROR,
    GET_COMPLETED_ASSESMENT_RESPONSE_SUCCESS,
    EDIT_ASSESSMENT_SUCCESS,
    DELETE_ASSESSMENT_ERROR,
    DELETE_ASSESSMENT_SUCCESS
} from '../actions/types';

const initialState = {
    openAssessments: [],
    assignedAssessments: [],
    myassessments: [],
    completedAssessments: [],
    openAssessmentsCount: 0,
    assignedAssessmentsCount: 0,
    myassessmentsCount: 0,
    completedAssessmentsCount: 0,
    assessment: null,
    isAssessmentFetched: false,
    assessmentUpdated:false,
    error: null,
    deleteError: null,
    isDeleted: false,
}

const assessmentReducer = (state, action) => {
    switch (action.type) {
        case GET_OPEN_ASSESMENT_RESPONSE_SUCCESS:
            return Object.assign({}, state, {
                openAssessments: action.payload.assessmentResponse || action.payload,
                openAssessmentsCount: action.payload.size || 0,
                assessment: null,
                error: null,
                isAssessmentFetched:true
            });
        case GET_ASSIGNED_ASSESMENT_RESPONSE_SUCCESS:
            return Object.assign({}, state, {
                assignedAssessments: action.payload.assessmentResponse || action.payload,
                assignedAssessmentsCount: action.payload.size || 0,
                error: null,
                isAssessmentFetched:true
            });
        case EDIT_ASSESSMENT_RESPONSE_SUCCESS:
            return Object.assign({}, state, { openAssessments: action.payload, error: null });
        case EDIT_ASSESSMENT_RESPONSE_ERROR:
            return Object.assign({}, state, { error: action.payload })
        case GET_USER_ASSESMENT_RESPONSE_SUCCESS:
            return Object.assign({}, state, {
                myassessments: action.payload.assessmentResponse || action.payload,
                myassessmentsCount: action.payload.size || 0,
                assessment: null,
                error: null,
                isAssessmentFetched:true
            });
        case GET_COMPLETED_ASSESMENT_RESPONSE_SUCCESS:
            return Object.assign({}, state, {
                completedAssessments: action.payload.assessmentResponse || action.payload,
                completedAssessmentsCount: action.payload.size || 0,
                assessment: null,
                error: null,
                isAssessmentFetched:true
            });
        case GET_ASSESMENT_RESPONSE_SUCCESS:
            return Object.assign({}, state, { assessment: action.payload, error: null, assessmentUpdated:false });
        case ASSESMENT_RESPONSE_ERROR:
            return Object.assign({}, state, { error: action.payload, isAssessmentFetched:true })
        case CLEAR_ASSESSMENT_ERROR:
            return Object.assign({}, state, { error: null, isAssessmentFetched:false, isDeleted:false, deleteError:null });
        case EDIT_ASSESSMENT_SUCCESS:
            return Object.assign({}, state, { assessmentUpdated:true })
        case DELETE_ASSESSMENT_SUCCESS:
                console.log('state is ', state);
                return Object.assign({}, state, { 
                    isDeleted: true , 
                    openAssessments: action.payload.openAssessments || state.openAssessments,
                    assignedAssessments: action.payload.assignedAssessments || state.assignedAssessments,
                    completedAssessments: action.payload.completedAssessments || state.completedAssessments,
                    myassessments: action.payload.myassessments || state.myassessments,
                    openAssessmentsCount: action.payload.openAssessmentsCount || state.openAssessmentsCount,
                    assignedAssessmentsCount:action.payload.assignedAssessmentsCount || state.assignedAssessmentsCount,
                    completedAssessmentsCount:action.payload.completedAssessmentsCount || state.completedAssessmentsCount,
                    myassessmentsCount:action.payload.myassessmentsCount || state.myassessmentsCount
                } )
        case DELETE_ASSESSMENT_ERROR:
                return Object.assign({}, state, { isDeleted: false, deleteError: action.payload} )
        default:
            if (state)
                return state;
            else
                return initialState
    }
}

export default assessmentReducer;
