import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import Permissions from './services/Permissions'
import Config from './services/config'
import { authProvider } from './services/AuthProvider';
import { Provider } from 'react-redux';
import IdleTimeOut from './components/IdleTimeOut';
import { ToastContainer, toast } from 'react-toastify';
import * as Survey from "survey-react";
import * as SurveyCore from "survey-core";
import * as widgets from "surveyjs-widgets";
import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import "select2/dist/js/select2.js";
import "jquery-bar-rating";
import './App.scss';
import 'antd/dist/antd.css';
import "survey-react/survey.css";
import "bootstrap/dist/css/bootstrap.css";
import "jquery-ui/themes/base/all.css";
import "nouislider/distribute/nouislider.css";
import "select2/dist/css/select2.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import "jquery-bar-rating/dist/themes/css-stars.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import 'survey-react/modern.css';
import "pretty-checkbox/dist/pretty-checkbox.css";
import 'react-toastify/dist/ReactToastify.min.css';
import "icheck/skins/square/blue.css";

window["$"] = window["jQuery"] = $;
require("emotion-ratings/dist/emotion-ratings.js");
require('icheck');

const queryString = require('query-string');

Survey.StylesManager.applyTheme("modern");

widgets.icheck(Survey, $);
widgets.icheck(SurveyCore, $);
widgets.prettycheckbox(Survey);
//widgets.select2(Survey, $);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey, $);
widgets.jqueryuidatepicker(Survey, $);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey, $);
widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey, $);
widgets.bootstrapslider(Survey);
widgets.emotionsratings(Survey, $);
widgets.prettycheckbox(SurveyCore);
widgets.select2(SurveyCore, $);
widgets.inputmask(SurveyCore);
widgets.jquerybarrating(SurveyCore, $);
widgets.jqueryuidatepicker(SurveyCore, $);
widgets.nouislider(SurveyCore);
widgets.select2tagbox(SurveyCore, $);
widgets.sortablejs(SurveyCore);
widgets.ckeditor(SurveyCore);
widgets.autocomplete(SurveyCore, $);
widgets.bootstrapslider(SurveyCore);
widgets.emotionsratings(SurveyCore, $);

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Logout = React.lazy(() => import('./views/Pages/Logout'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));



class App extends Component {

    constructor(props) {
        super(props)
        this.idleTimer = null
        this.state = { }
    }

    redirectToPasswordReset() {
        const adConfig = Config.getADConfig()
        let qs = queryString.stringify({
            p: adConfig.policy_reset_password,
            client_id: adConfig.clientId,
            nonce: 'defaultNonce',
            redirect_uri: window.location.origin,
            prompt: 'login',
            response_type: 'id_token',
            scope: 'openid',
        })
        window.location.href = `https://${adConfig.tenantName}.b2clogin.com/${adConfig.tenantDomain}/oauth2/v2.0/authorize?${qs}`
    }

  logoutManually() {
    const adConfig = Config.getADConfig()
    const redirectUri = encodeURI(window.location.origin)
    window.location.href = `https://${adConfig.tenantName}.b2clogin.com/${adConfig.tenantDomain}/${adConfig.policy}/oauth2/v2.0/logout?post_logout_redirect_uri=${redirectUri}`
  }

  checkSession(logout) {
    const msalError = localStorage.getItem('msal.error');
    if (msalError) {
      // Log possible errorcodes
      console.log('>>>>>>>>>>>>> STUFF FOR DEBUGGING >>>>>>>', msalError)
      // Clear cache on no activity
      if (msalError === 'interaction_required') {
        (typeof logout === 'function') ? logout() : console.log();
      }
    }
  }

  clearBrowserCache() {
    localStorage.clear();
    sessionStorage.clear();
    let cookies = (document.cookie || '').split(";");
    for (let i = 0; i < cookies.length; ++i) {
      let myCookie = cookies[i];
      let pos = myCookie.indexOf("=");
      let name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

    render() {
    // Prevent page being displayed in an iframe
    if (window.self !== window.top) return (<>This webpage can't be displayed in an iframe.</>)
    // Render
    if(this.idleTimer)
        console.log('Time remaining ', this.idleTimer.getRemainingTime());
    
    return (
        <Provider store={this.props.store}>
            <AzureAD provider={authProvider} forceLogin={true} reduxStore={this.props.store}>
                {({ login, logout, authenticationState, error, accountInfo }) => {
                if (error) {
                    // User cancelled the flow
                    if ((error.errorMessage || '').indexOf('AADB2C90091') >= 0) return login()
                    // User forgot the password
                    if ((error.errorMessage || '').indexOf('AADB2C90118') >= 0) return this.redirectToPasswordReset()
                    // Social sign in AADB2C90273
                    // Session expired
                    if ((error.errorMessage || '').indexOf('login is already in progress') >= 0) return (
                    <>
                        Redirecting... Click <a href="/#" onClick={login}>here</a> if not redirected automatically.
                    </>
                    )
                    // Other possible errors. WIP
                    if (error.errorMessage) console.log('>>>>>>>>>>>>> STUFF FOR DEBUGGING >>>>>>>', error.errorMessage)
                }

                const isInProgress = authenticationState === AuthenticationState.InProgress;
                const isAuthenticated = authenticationState === AuthenticationState.Authenticated;
                const isUnauthenticated = authenticationState === AuthenticationState.Unauthenticated;
                if (isInProgress) {
                    return (
                    <>Redirecting...</>
                    );
                }
                else if (isUnauthenticated) {
                    this.clearBrowserCache()
                    login()
                    return
                }
                else {
                    const account = ((accountInfo || {}).account || {}).idToken
                    const jobTitle = account.jobTitle ? account.jobTitle.split(';') : (account.roles || [])
                    // Redirect to error page if user is authenticated by do not have enough permission
                    const isValidRole = Permissions.isValidRole( jobTitle || '')
                    if (!isValidRole && isAuthenticated) {
                        return (
                            <React.Fragment>
                                <div className="app flex-row align-items-center">
                                    <Container>
                                        <Row className="justify-content-center">
                                            <Col md="6">
                                            <div className="clearfix">
                                                <h4 className="pt-3">Pending verification</h4>
                                                <p>The admin is currently processing your onboarding application and you will be contacted once access is granted.</p>
                                                <a href="/#" onClick={() => this.handleLogout(logout)} className="text-muted">Click here after onboarding</a>
                                            </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </React.Fragment>
                        );
                    }
                    // Redirect to the home page if user is authenticated and have enough permission
                    return (
                    <HashRouter>
                        <IdleTimeOut logout={() => this.handleLogout(logout)} />
                        <ToastContainer position={toast.POSITION.BOTTOM_LEFT} hideProgressBar={true}/>
                        <React.Suspense fallback={loading()}>
                            <Switch>
                                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                                <Route exact path="/logout" name="Logout Page" render={props => <Logout {...props} logout={logout} />} />
                                <Route path="/" name="Home" render={props => <DefaultLayout {...props} logout={logout} />} />
                            </Switch>
                        </React.Suspense>
                    </HashRouter>
                    );
                }
                }}
            </AzureAD>
            
        </Provider>

    );
    }

    handleLogout = async (logout) => {
        await Permissions.logout();
        logout()
    }

}

export default App;
