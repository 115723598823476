
class Config {

    isProduction() {
        return this.get('NODE_ENV') === 'production';
    }

    isDevelopment() {
        return this.get('NODE_ENV') === 'development';
    }

    isStaging() {
        return window.location.origin === 'https://unify.eco'
    }

    get(key, defaultValue) {
        return process.env[key] || this.envConfig[key] || defaultValue;
    }

    getN(key, defaultValue) {
        return process.env[key] ? parseInt(process.env[key], 10) : defaultValue;
    }

    getAuthMode() {
        return process.env.REACT_APP_AUTH_MODE
    }

    // TODO: Move this to ConfigMap
    getADConfig() {
        let authMode = process.env.REACT_APP_AUTH_MODE;
        let clientID = authMode === 'AD' ? process.env.REACT_APP_AD_CLIENT_ID : process.env.REACT_APP_B2C_CLIENT_ID
        let tenantName = authMode === 'AD' ? process.env.REACT_APP_B2C_TENANT_NAME : process.env.REACT_APP_B2C_TENANT_NAME
        let tenantDomain = authMode === 'AD' ? process.env.REACT_APP_AD_TENANT_DOMAIN : process.env.REACT_APP_B2C_TENANT_DOMAIN
        let scope = process.env.REACT_APP_SCOPE
        let policy = process.env.REACT_APP_SIGNIN_POLICY
        let resetPasswordPolicy = process.env.REACT_APP_RESET_PASSWORD_POLICY
        return {
            clientId: clientID,
            tenantName,
            tenantDomain,
            scope,
            policy,
            policy_reset_password: resetPasswordPolicy
        }

        /* if (this.isStaging()) {

            return {
                clientId: authMode === 'AD' ? 'ad0e3c2b-e5fa-4906-824c-09e70eb0a745' : '42fd984a-cc6c-4f0d-80fb-55798d8e192b',
                tenantName: 'onedigitalb2cstaging',
                scope: authMode === 'AD' ? 'ad/api/Read.All' : 'api/Read.All',
                policy: 'B2C_1_sgcleansignupsignin_staging',
                policy_reset_password: 'B2C_1_sgcleanpasswordreset_staging'
            }
        } else if (this.isProduction()) {
            return {
                clientId:  authMode === 'AD' ? '' :  'c7f50285-22be-481e-8feb-3526288d956e',
                tenantName: 'sgcleanoutlookb2c',
                scope: 'api/Read.All',
                policy: 'B2C_1_sgcleansignupsignin',
                policy_reset_password: 'B2C_1_sgcleanresetpassword'
            }
        } else {
            return {
                clientId: authMode === 'AD' ? '' : 'f69aafa9-31a3-4a18-9ecd-32c8c8135115',
                tenantName: 'onedigitalb2c',
                scope: authMode === 'AD' ? 'test/Read.All' : 'api/Read.All',
                policy: 'B2C_1_sgcleansignupsignin_dev',
                policy_reset_password: 'B2C_1_sgcleanresetpassword_dev'
            }
        } */
    }

    getDashBoardURL() {
        const ASSESSMENT_STATUS_URL = process.env.REACT_APP_ASSESSMENT_STATUS_URL
        const ASSESSMENT_RESULT_URL = process.env.REACT_APP_ASSESSMENT_RESULT_URL
        const ASSESSMENT_QUESTION_URL = process.env.REACT_APP_ASSESSMENT_QUESTION_URL

        return {
            assessment_status_url: ASSESSMENT_STATUS_URL,
            assessment_result_url:ASSESSMENT_RESULT_URL ,
            assessment_question_url: ASSESSMENT_QUESTION_URL
        }
    }
}

export default new Config()
