import { authProvider } from './AuthProvider';
import Permission from './Permissions';
import { history } from '../store';
class Api {
    constructor() {
        this.baseUrl = process.env.REACT_APP_BASE_URL
    }
/**
   *
   * @param path
   * @returns {Promise<any|never>}
   * @private
   */
  async _get(path) {
    return this._call('GET', path);
  }

  /**
   *
   * @param path
   * @param data
   * @returns {Promise<any|never>}
   * @private
   */
  async _patch(path, data) {
    return this._call('PATCH', path, data);
  }

  /**
   *
   * @param path
   * @param data
   * @returns {Promise<any|never>}
   * @private
   */
  async _post(path, data) {
    return this._call('POST', path, data);
  }

  /**
   *
   * @param path
   * @param data
   * @returns {Promise<any|never>}
   * @private
   */
  async _put(path, data) {
    return this._call('PUT', path, data);
  }

  /**
   *
   * @param path
   * @returns {Promise<any|never>}
   * @private
   */
  async _delete(path) {
    return this._call('DELETE', path);
  }

  /**
   *
   * @param path
   * @param method
   * @param data
   * @returns {Promise<any | never>}
   * @private
   */
  async _call(method = 'GET', path, data = undefined) {
    // Initialise the body and headers with defaults
    let requestBody = undefined;
    let requestHeaders = {
      'Content-Type': 'application/json',
      'Company': `${Permission.getCurrentCompany()}`
    };

    // Prepare the body
    if (data instanceof FormData) {
      // Leave FormData untouched
      requestHeaders = {
        'Company': `${Permission.getCurrentCompany()}`
      };
      requestBody = data;
    } else if (typeof data !== 'undefined') {
      // JSONify everything else
      requestBody = JSON.stringify(data);
    }

    // Add the Authorization header if a token is set
    const token = await authProvider.getAccessToken();
    if (token && token.accessToken) {
      requestHeaders['Authorization'] = 'Bearer ' + token.accessToken;
    }

    if (!path.startsWith('http')) {
      path = this.baseUrl + path
    }

    // Execute the fetch inside a promise
    return new Promise((resolve, reject) => {
      fetch(path, {
        method: method,
        mode: 'cors',
        body: requestBody,
        headers: requestHeaders,
      })
        .then(
          async res => {
              let result = await res.json();

              if(result.statusCode === 200 || result.statusCode === 201) {
                resolve(result);
              }else {
                  //reject PRomise with appropriate error
                  if (result.statusCode === 401){
                    history.replace('/logout', { code: (result.error || {}).code});
                    return;
                  }
                   
                  const error = result.error || result
                  reject(error);
              }
          },
          error => {
            reject(error);
            //logger.warn('E_NETWORK_ERROR', error);
            //reject(new UnifyError('E_NETWORK_ERROR', error));
          },
        ).catch(error => {
          console.log('Error in catch block ', error);
          reject(error);
        });
    });
  }

  async _downloadFile(path) {
    let requestHeaders = {
      'Company': `${Permission.getCurrentCompany()}`
    };
    const token = await authProvider.getAccessToken();
    if (token && token.accessToken) {
      requestHeaders['Authorization'] = 'Bearer ' + token.accessToken;
    }
    return new Promise((resolve, reject) => {
      fetch(this.baseUrl + path, {
        method: 'GET',
        mode: 'cors',
        headers: requestHeaders,
      }).then(async res => {
        if(res.status !== 200){
          const error = res.errorMessage || 'Error while downloading File'
          reject(error);
        }
        let blob = await res.blob()
        resolve(blob)
      }).catch(error => {
          reject(error);
      });
    });
  }


  async _downloadAsBlob(path, fileInput) {
    // Initialise the body and headers with defaults
    // let requestBody = undefined;
    let requestHeaders = {
      'Company': `${Permission.getCurrentCompany()}`
    };

    var requestBody = new FormData();
    if (fileInput) requestBody.append("file", fileInput, "report." + (fileInput.name || '.pdf').split('.').pop());

    // Add the Authorization header if a token is set
    const token = await authProvider.getAccessToken();
    if (token && token.accessToken) {
      requestHeaders['Authorization'] = 'Bearer ' + token.accessToken;
    }

    if (!path.startsWith('http')) {
      path = this.baseUrl + path
    }

    // Execute the fetch inside a promise
    return new Promise((resolve, reject) => {
      fetch(path, {
        method: 'POST',
        mode: 'cors',
        body: requestBody,
        headers: requestHeaders,
      })
        .then(
          async res => {
              if(res.status === 200 || res.status === 201) {
                resolve(await res.blob());
              }
              else {
                  const error = res.errorMessage || 'Error while downloading File'
                  reject(error);
              }
          },
          error => {
            console.log('error is ', error);
            reject(error);
          },
        ).catch(error => {
          console.log('Error in catch block ', error);
          reject(error);
        });
    });
  }
}

const api = new Api();

export default api;
